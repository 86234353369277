import { Map } from 'immutable';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { createSimpleSelector } from '@dbh/reselect-extra';
import _noop from 'lodash/noop';
import 'react-immutable-proptypes';
import '@dbh/generic-types';

const REDUX_AND_SAGA_KEY="routeInternals";const REACT_ROUTER_MATCH="@dbh/redux-route-internals/REACT_ROUTER_MATCH";

const initialState=Map({reactRouterPageType:null,isPaginated:null,adminDevDependencies:null}),routeInternalsReducer=function(a,b){switch(void 0===a&&(a=initialState),b.type){case REACT_ROUTER_MATCH:{const{payload:{PAGE_TYPE:c,isPaginated:d,adminDevDependencies:e}}=b;return a.merge({reactRouterPageType:c,isPaginated:d,adminDevDependencies:e})}default:return a}};/**
 * Merge route "internals" into the global application state.
 * @param {Immutable.Map} state The current state.
 * @param {Object} event .
 * @return {Immutable.Map} The next state.
 */var routeInternalsReducer$1 = routeInternalsReducer;

/**
 * @typedef {string} WebcontrolsReference The `reference` field of a
 * "Navigation Menu" in the `admindev`.
 * @typedef {Immutable.List<WebcontrolsReference>} WebcontrolsReferences
 * @typedef {Immutable.Map<{
 * webcontrolsReferences: WebcontrolsReferences
 * }>} AdminDevDependencies Data from the `admindev` required to render a page.
 */const adminDevDependenciesImmutablePropType=_noop;

/**
 * @typedef {import('./types').AdminDevDependencies} AdminDevDependencies
 *//**
 * A page container has been loaded in the application.
 * @param {Object} param0 Internal data of `React Router`.
 * @param {string} param0.PAGE_TYPE .
 * @param {boolean} param0.isPaginated .
 * @param {AdminDevDependencies} param0.adminDevDependencies .
 * @return {Object} .
 */const reactRouterMatch=withConformsTo("reactRouterMatch",[],a=>{let{PAGE_TYPE:b,isPaginated:c,adminDevDependencies:d}=a;return {type:REACT_ROUTER_MATCH,payload:{PAGE_TYPE:b,isPaginated:c,adminDevDependencies:d}}});

/**
 * @typedef {import('./types').AdminDevDependencies} AdminDevDependencies
 */const selectRouteInternals=a=>a?a.get(REDUX_AND_SAGA_KEY):void 0;/**
 * Returns a selector that returns the `admindev` dependencies.
 * @return {AdminDevDependencies} .
 */const makeSelectAdminDevDependencies=()=>createSimpleSelector(selectRouteInternals,["adminDevDependencies"]);

export { REACT_ROUTER_MATCH, REDUX_AND_SAGA_KEY, adminDevDependenciesImmutablePropType, makeSelectAdminDevDependencies, reactRouterMatch, routeInternalsReducer$1 as routeInternalsReducer, selectRouteInternals };
