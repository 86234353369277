import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/*
 *
 * Constants: `@dbh/regex-utils`.
 *
 */const UNSAFE_REGEX_CHARACTERS=/[-[\]{}()*+?.,\\^$|#\s]/g;

/**
 * Returns the incoming string with the regex special characters escaped,
 * in order to be safely used with the `RegExp` constructor.
 * @example
 * ```js
 * let unsafeString = '(..\\';
 *
 * let errorRegex = new RegExp(unsafeString); // BAD - Error
 *
 * let safe Regex =
 *   new RegExp(escapeRegexSpecialCharacters(unsafeString)); // GOOD - /\(\.\.\\\\/
 * ```
 * @param {string} text Might contain regex special characters.
 * @return {string} The input with special characters escaped, safe to be
 * used with `RegExp` constructor.
 * @throws {TypeError} If `text` is not a string.
 * @see {@link https://stackoverflow.com/a/9310752}
 */const escapeRegexSpecialCharacters=a=>{if("string"==typeof a||a instanceof String)return a.replace(UNSAFE_REGEX_CHARACTERS,"\\$&");throw new TypeError("escapeRegexSpecialCharacters: expected input to be a string, got "+a)};var escapeRegexSpecialCharacters$1 = escapeRegexSpecialCharacters;

/**
 * Returns a `RegExp` that matches exactly the given string.
 * @param {string} text It can also be a text that contains a `regex`.
 * @return {RegExp} .
 * @throws {TypeError} If `text` is not a string, or it is already a `RegExp`.
 * @see {@link https://stackoverflow.com/a/9310752}
 */const createExactMatchRegexFromString=withConformsTo("createExactMatchRegexFromString",[],a=>{let b=a;return b.endsWith("$")||(b+="$"),b.startsWith("^")||(b="^"+b),new RegExp(b)},{allowUnusedParameters:!0});var createExactMatchRegexFromString$1 = createExactMatchRegexFromString;

const includesAtLeastOneAlphabetRegex=/[a-zA-Z]/,includesAtLeastOneAlphabet=withConformsTo("includesAtLeastOneAlphabet",[],a=>includesAtLeastOneAlphabetRegex.test(a));/**
 * Returns true if the passed text contains at least one alphabetic letter.
 * @param {string} text .
 * @return {boolean} .
 */var includesAtLeastOneAlphabet$1 = includesAtLeastOneAlphabet;

export { createExactMatchRegexFromString$1 as createExactMatchRegexFromString, escapeRegexSpecialCharacters$1 as escapeRegexSpecialCharacters, includesAtLeastOneAlphabet$1 as includesAtLeastOneAlphabet };
