import 'prop-types';
import { all, put, take, fork, call } from 'redux-saga/effects';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import Sentry from '@dbh/sentry';
import { ApiRequestError } from '@dbh/request-errors';
import { channel } from 'redux-saga';
import { isServerSideRendering } from '@dbh/environment';

/**
 * It `puts` `redux` events sequentially in a saga. The events are NOT be executed
 * in parallel, due to the single threaded nature of the language. They will be
 * `put` and executed one at a time.
 * @param {Array} events The events to `put`.
 * @return {any} The result of `yield all`.
 */var putAll = withConformsTo("putAll",[],function*(a){return yield all(a.map(a=>put(a)))});

/**
 * Takes care of reporting a `saga` error to `sentry`, and showing it to the
 * developer in the browser (if not in production `www`).
 * @param {Error} error .
 */const handleSagaError=withConformsTo("handleSagaError",[],a=>{// We don't want ot send `dryRun` `API` calls errors to `sentry`, as they are
// expected to be form validation errors. So, in case of `API` request errors,
// we ask it if it has to be sent to `sentry`.
const b=!(a instanceof ApiRequestError)||a.shouldSendToSentry();b&&Sentry.captureException(a);});var handleSagaError$1 = handleSagaError;

/**
 * Listens to a pattern and triggers once, the very first time, the saga passed
 * as argument.
 * @param {string|Array|Function} patternOrChannel Triggers the saga.
 * @see {@link https://redux-saga.js.org/docs/api/#takepattern}
 * @param {Function} saga A saga "generator".
 * @param {Array<any>?} args If passed, they are passed to the saga.
 * Note that the event is always the last argument, adopting the same behaviour
 * of `takeLatest` and `takeLeading`.
 * @see {@link https://redux-saga.js.org/docs/api#takelatestpattern-saga-args}
 * @yields {Object}.
 */function*takeOnce(a,b){const c=yield take(a);for(var d=arguments.length,e=Array(2<d?d-2:0),f=2;f<d;f++)e[f-2]=arguments[f];yield fork(b,...e,c);}

/**
 * Creates a `redux-saga` channel (`SSR` only), forks the provided channel
 * watcher, sends the provided event and closes the channel.
 * @param {Generator} sagaChannelWatcher Channels are required to perform
 * "cross-saga communication" in `SSR`.
 * @param {Object} event `redux`.
 */var forkSagaChannelAndPutEvent = withConformsTo("forkSagaChannelAndPutEvent",[],function*(a,b){//  We do not need nor want to use `redux-saga` channels or to directly call
// sagas in the Front End, so we check if we are in `SSR`.
if(isServerSideRendering()){const c=yield call(channel);yield fork(a,c),yield put(c,b),c.close();}});

/*
 *
 * `monitorSagas`: `@dbh/redux-saga-extra`.
 *
 */const monitorSagas=a=>{const b=[],c=a.runSaga;// eslint-disable-next-line no-param-reassign
a.runSaga=d=>{const e=c.call(a,d);return b.push(e),e};return ()=>Promise.all(b.map(a=>a.toPromise()))};var monitorSagas$1 = monitorSagas;

export { forkSagaChannelAndPutEvent, handleSagaError$1 as handleSagaError, monitorSagas$1 as monitorSagas, putAll, takeOnce };
